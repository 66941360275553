@import url("pages.css");
@import url("header.css");

html {
  --max-mobile-width: 450px;
  --max-tablet-width: 950px;
  --max-desktop-width: 1500px;
  --raspberry: #D7288B;
  --candy: #ffbed5;
  --white: #fff3f7;
  --grey: #6b717e;
  --accessible-white: #ffffff;
  --accessible-grey: #525660;
  --raleway: "Raleway", sans-serif;
}

/* below 450px */
* {
  margin: 0;
  padding: 0;
  margin: 0 auto;
  max-width: var(--max-mobile-width);
}

h1,
h2,
h3,
h4 {
  font-family: var(--raleway);
  color: var(--raspberry);
  line-height: 1.5em;
  text-align: center;
}

h3,
h2 {
  color: var(--raspberry);
}

h4 {
  color: var(--grey);
}

a {
  text-decoration: none;
  color: inherit;
  font-weight: bold;
  font-family: inherit;
}

a:hover {
  color: var(--raspberry);
  font-weight: bold;
}

p {
  font-family: "Playfair Display", serif;
}

li {
  list-style: none;
}

.App {
  margin: 10px;
  background-color: var(--white);
}

.content {
  min-height: calc(100vh - 260px);
  padding-bottom: 15px;
}

.pageTitle {
  text-align: center;
  font-weight: bold;
  margin: 15px auto;
  text-decoration: underline;
}

.subtitle {
  margin: 10px auto;
}

.emoji {
  font-size: 50px;
  line-height: 1.5em;
  margin: 10px;
}

.filterButton {
  padding: 5px 10px;
  margin: 10px;
  border: none;
  background-color: var(--white);
  color: var(--raspberry);
  font-family: var(--raleway);
  font-weight: bold;
  font-size: 14px;
}

.filterButton:hover {
  color: var(--white);
  background-color: var(--raspberry);
}

/* tablet */
@media (min-width: 500px) {
  * {
    max-width: var(--max-tablet-width);
  }
}

/* desktop */
@media (min-width: 1000px) {
  * {
    max-width: var(--max-desktop-width);
  }
}

.elevator-button {
  float: right;
  display: flex;
  justify-content: center;
  border: none;
  background-color: transparent;
  color: var(--raspberry);
  font-family: var(--raleway);
  font-size: 15px;
}

.elevator-button img {
  height: 20px;
  width: 20px;
  margin-left: 10px;
}